<template>
  <section class="content">
    <div
      class="modal show"
      tabindex="-1"
      role="dialog"
      ref="formFilter"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit="submitFilter">
            <div class="modal-header">
              <h5 class="modal-title">Filter Status</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <h2>
                  <span aria-hidden="true">&times;</span>
                </h2>
              </button>
            </div>
            <div class="modal-body">
              <div class="container">
                  <label class="control-label" v-if="sUmum">STATUS UMUM</label>
                <div class="row" v-if="sUmum">
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="pending"
                        @change="onChange($event)"
                        v-model="filter.rpick"
                      />
                      <label class="form-check-label" for="pending">
                        Pending
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="prosesbuyer"
                        @change="onChange($event)"
                        v-model="filter.delgo"
                      />
                      <label class="form-check-label" for="prosesbuyer">
                        Proses
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="delivered"
                        @change="onChange($event)"
                        v-model="filter.delok"
                      />
                      <label class="form-check-label" for="delivered">
                        Terkirim
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="returshipper"
                        @change="onChange($event)"
                        v-model="filter.retgo"
                      />
                      <label class="form-check-label" for="returshipper">
                        Proses Retur
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="returnok"
                        @change="onChange($event)"
                        v-model="filter.retok"
                      />
                      <label class="form-check-label" for="returnok">
                        Retur Diserahkan
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="returned"
                        @change="onChange($event)"
                        v-model="filter.retacc"
                      />
                      <label class="form-check-label" for="returned">
                        Retur Diterima
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="deler"
                        @change="onChange($event)"
                        v-model="filter.deler"
                      />
                      <label class="form-check-label" for="deler">
                        Bermasalah
                      </label>
                    </div>
                  </div>
                </div>
                  <label class="control-label" v-if="sCod">STATUS COD</label>
                <div class="row" v-if="sCod">
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="cod" name="scod" @change="onChange($event)" v-model="filter.status_cod" value="cod" />
                        <label for="cod">COD</label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="noncod" name="scod" @change="onChange($event)" v-model="filter.status_cod" value="noncod" />
                        <label for="noncod">NON COD</label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="allstatus_cod" name="scod" @change="onChange($event)" v-model="filter.status_cod" value="allstatus_cod" />
                        <label for="allstatus_cod">SEMUA</label>
                    </div>
                  </div>
                </div>
                  <label class="control-label" v-if="sTicket">STATUS TICKET</label>
                <div class="row" v-if="sTicket">
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="open" name="sticket" @change="onChange($event)" v-model="filter.status_ticket" value="open" />
                        <label for="open">OPEN</label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="closed" name="sticket" @change="onChange($event)" v-model="filter.status_ticket" value="closed" />
                        <label for="closed">CLOSED</label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <div class="icheck-material-orange icheck-inline">
                        <input type="radio" class="form-check-input" id="allstatus_ticket" name="sticket" @change="onChange($event)" v-model="filter.status_ticket" value="allstatus_ticket" />
                        <label for="allstatus_ticket">SEMUA</label>
                    </div>
                  </div>
                </div>
                  <label class="control-label" v-if="sClaim">STATUS KLAIM</label>
                <div class="row justify-content-between" v-if="sClaim"> 
                  <div class="form-group col-auto">
                    <div class="icheck-material-orange icheck-inline">
                      <input type="radio" class="form-check-input" id="pending_claim" name="sclaim" @change="onChange($event)" v-model="filter.status_claim" value="pending" />
                      <label for="pending_claim" class="form-check-label">Diproses</label>
                    </div>
                  </div>
                  <div class="form-group col-auto">
                    <div class="icheck-material-orange icheck-inline">
                      <input type="radio" class="form-check-input" id="approve" name="sclaim" @change="onChange($event)" v-model="filter.status_claim" value="approve" />
                      <label for="approve" class="form-check-label">Disetujui</label>
                    </div>
                  </div>
                  <div class="form-group col-auto">
                    <div class="icheck-material-orange icheck-inline">
                      <input type="radio" class="form-check-input" id="not_approve" name="sclaim" @change="onChange($event)" v-model="filter.status_claim" value="not_approve" />
                      <label for="not_approve" class="form-check-label">Ditolak</label>
                    </div>
                  </div>
                  <div class="form-group col-auto">
                    <div class="icheck-material-orange icheck-inline">
                      <input type="radio" class="form-check-input" id="not_claim" name="sclaim" @change="onChange($event)" v-model="filter.status_claim" value="not_claim" />
                      <label for="not_claim" class="form-check-label">Belum Diclaim</label>
                    </div>
                  </div>
                  <div class="form-group col-4" v-if="false">
                    <div class="icheck-material-orange icheck-inline">
                      <input type="radio" class="form-check-input" id="banding" name="sclaim" @change="onChange($event)" v-model="filter.status_claim" value="banding" />
                      <label for="banding" class="form-check-label">Banding</label>
                    </div>
                  </div>
                  <div class="form-group col-4" v-if="false">
                    <div class="icheck-material-orange icheck-inline">
                      <input type="radio" class="form-check-input" id="void" name="sclaim" @change="onChange($event)" v-model="filter.status_claim" value="void" />
                      <label for="void" class="form-check-label">Void</label>
                    </div>
                  </div>
                  <div class="form-group col-4" v-if="false">
                    <div class="icheck-material-orange icheck-inline">
                      <input type="radio" class="form-check-input" id="allstatus_claim" name="sclaim" @change="onChange($event)" v-model="filter.status_claim" value="allstatus_claim" />
                      <label for="allstatus_claim" class="form-check-label">Semua</label>
                    </div>
                  </div>
                </div>
                  <label class="control-label" v-if="sOther">LAINNYA</label>
                <div class="row" v-if="sOther">
                    <div class="form-group col-4">
                        <div class="icheck-material-orange icheck-inline form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="updated"
                            @change="onChange($event)"
                            v-model="filter.updated"/>
                        <label class="form-check-label" for="updated">
                          Perubahan Ongkir
                        </label>
                        </div>
                    </div>
                    <div class="form-group col-4" v-if="voidRole">
                        <div class="icheck-material-orange icheck-inline form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="void"
                            @change="onChange($event)"
                            v-model="filter.void"/>
                        <label class="form-check-label" for="void">
                            Void
                        </label>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Cari</button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Batal
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { auth } from "@/libs/hxcore";
import $ from "jquery";
import "icheck-material";
export default {
  name: "Historyfilter",
  props: { 
    show: { default: false, type: Boolean },
    value: { type: Object },
    sUmum: { default: true, type: Boolean },
    sCod: { default: true, type: Boolean },
    sTicket: { default: true, type: Boolean },
    sClaim: { default: true, type: Boolean },
    sOther: { default: true, type: Boolean }
  },
  data() {
    return {
      errors: [],
      filter: {},
    };
  },
  components: {},
  computed: {
    voidRole() {
        const allowRoles = ['1', '2', '4', '10'];
        return allowRoles.includes(this.roleid);
    },
  },
  created() {
    var uinfo = auth.user();
    if (uinfo) {
      this.roleid = uinfo.role_id;
    }
    this.filter = this.value;
  },
  methods: {
    onChange(event) {
      if (this.filter.rpick == true) {
        this.filter.rpick = 1;
      } else {
        this.filter.rpick = 0;
      }

      if (this.filter.delgo == true) {
        this.filter.delgo = 1;
      } else  {
        this.filter.delgo = 0;
      }

      if (this.filter.delok == true) {
        this.filter.delok = 1;
      } else  {
        this.filter.delok = 0;
      }

      if (this.filter.retgo == true) {
        this.filter.retgo = 1;
      } else  {
        this.filter.retgo = 0;
      }

      if (this.filter.retok == true) {
        this.filter.retok = 1;
      } else  {
        this.filter.retok = 0;
      }

      if (this.filter.retacc == true) {
        this.filter.retacc = 1;
      } else  {
        this.filter.retacc = 0
      }

      if (this.filter.deler == true) {
        this.filter.deler = 1;
      } else  {
        this.filter.deler = 0;
      }

      let val = this.$emit("input", this.filter);
      // console.log("input: ", val);
    },
    showDialog(e) {
      $(this.refs.formFilter).modal(e ? "show" : "hide");
    },
    submitFilter: function (ev) {
    // this.table.api().ajax.reload();
        let val = this.$emit("change", this.filter);
        $(this.$refs.formFilter).modal("hide");
        ev.preventDefault();
    },
  },
  mounted() {
    if (this.filter.rpick) {
      $("#pending").prop("checked", true);
      this.filter.rpick = 1;
    } 
    if (this.filter.delgo) {
      $("#prosesbuyer").prop("checked", true);
      this.filter.delgo = 1;
    }
    if (this.filter.delok) {
      $("#delivered").prop("checked", true);
      this.filter.delok = 1;
    }
    if (this.filter.retgo) {
      $("#returshipper").prop("checked", true);
      this.filter.retgo = 1;
    }
    if (this.filter.retok) {
      $("#returnok").prop("checked", true);
      this.filter.retok = 1;
    }
    if (this.filter.retacc) {
      $("#returned").prop("checked", true);
      this.filter.retacc = 1;
    }
    if (this.filter.deler) {
      $("#deler").prop("checked", true);
      this.filter.deler = 1;
    }
  },
  watch: {
    show: function (val, old) {
      $(this.$refs.formFilter).modal("show");
    },
    value: function (val, old) {
      this.filter = val;
    },
  },
};
</script>
<style scoped>
</style>